<template>
  <b-card>
    <g-table
      ref="benefitsDeductions-table"
      :items="items"
      :columns="tableColumns"
      :no-action="true"
      per-page="25"
      :total-rows="totalRows"
      :createButton="{ visiable: true, permission: 'addBenefitAndDeduction' }"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          $router.push({ name: 'benefitsAndDeductionsNew' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <!-- define edit button -->
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editBenefitAndDeduction'"
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            :disabled="item.isSalaryCalculated"
            @click="edit(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
              class="clickable"
            />
          </b-button>
          <b-button
            v-permission="'viewBenefitAndDeduction'"
            v-b-tooltip.hover.top="item.attachmentUrl !== null
              ? $t('hasAttachMents')
              : $t('hasNotAttachMents')
            "
            data-action-type="download"
            variant="flat-warning"
            class="btn-icon"
            size="sm"
            @click="(v) => {
              if (item.attachmentUrl !== null) {
                downloadItem(item);
              }
            }
            "
          >
            <feather-icon
              :icon="item.attachmentUrl !== null ? 'FolderPlusIcon' : 'FolderIcon'"
              stroke="orange"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            variant="flat-primary"
            class="btn-icon"
            size="sm"
            @click="
              (v) => {
                print(item);
              }
            "
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="PrinterIcon"
              stroke="green"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteBenefitAndDeduction'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            :disabled="item.isSalaryCalculated"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script>
import GTable from '@/pages/Shared/Table.vue';
import reportMixin from '@/mixin/reportMixin';
import { rows } from '@/libs/acl/Lookups';
import saveAs from 'file-saver';

export default {
  components: {
    GTable,
  },
  mixins: [reportMixin],
  data() {
    return {
      items: [],
      totalRows: 0,
      currentPage: 1,
      isTableBusy: false,
      filterOn: [],
      perPage: 25,
      rows: rows,
      modal: false,
      filter: {},
      Type: null,
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        {
          key: 'employeeArabicName',
          label: this.$t('employeeName'),
          sortable: true,
        },
        { key: 'jobNumber', label: this.$t('jobNumber'), sortable: true },
        {
          key: 'itemType',
          label: this.$t('type'),
          sortable: true,
          formatter: (key, value, item) => {
            if (item.itemType === 'earning') {
              return `<span class="badge badge-light-success p-50">${this.$t(
                'benefit'
              )}</span>`;
            }
            if (item.itemType === 'deduction') {
              return `<span class="badge badge-light-primary p-50">${this.$t(
                'Deduction'
              )}</span>`;
            }
          },
        },
        { key: 'total', label: this.$t('value'), sortable: true },
        { key: 'fromYear', label: this.$t('fromYear') },
        { key: 'fromMonth', label: this.$t('fromMonth') },
        { key: 'toYear', label: this.$t('toYear') },
        { key: 'toMonth', label: this.$t('toMonth') },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
     this.getItems();
  },
  methods: {
    refreshItems() {
      this.$refs['benefitsDeductions-table'].refreshTable();
    },
    getItems() {
      this.get({ url: 'EmployeeBenefitsDeductions' }).then((data) => {
         this.items = data.pageData;
      });
    },
    itemsProvider(ctx, callback) {
      const {
        currentPage,
        perPage,
        sortBy,
        sortDesc
      } = ctx;
      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      var params = `?isAdvancePayment=false&pageNumber=${currentPage}&pageSize=${perPage}&`;
      params += this.getFilterObj(this.filter);
      this.isTableBusy = true;
      this.get({ url: `EmployeeBenefitsDeductions${params}` })
        .then(({ pageData, totalRecords }) => {
          this.isTableBusy = false;
          this.totalVals = 0;
          this.totalRows = totalRecords;
          callback(pageData);
          // // then set items for excel and pdf
          this.itemsArray = pageData;
          this.items = pageData.data
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({
            url: 'EmployeeBenefitsDeductions',
            id: item.id,
          }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },
    print(item) {
      const reportName = this.isRight ? 'BenefitAndDeductionReport-ar' : 'BenefitAndDeductionReport-en';
      const printedItem = {
        id: item.id,
        Type: item.itemType === 'earning' ? 1 : 2,
      };
      this.printReport(reportName, printedItem);
    },
    edit(item) {
      this.$router.push({
        name: 'benefitsAndDeductionsEdit',
        params: { id: item.id },
      });
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    }
  },
};
</script>
<style></style>
